<template>
  <div class="layout-scanner">
    <company-logo />
    <router-view></router-view>
    <app-bar />
  </div>
</template>

<script>
export default {
  name: 'MainLayout',
}
</script>

<style lang="scss">

.layout-scanner {
  min-height: 95vh;
  padding-bottom: calc(70px + env(safe-area-inset-bottom));
  padding-top: calc(0px + env(safe-area-inset-top));
}

.n-input {
  margin-bottom: 20px;
  width: 370px;

  .n-content {
    border: 2px solid #CFCECE !important;
    border-radius: 16px !important;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1) !important;
  }
}
</style>
